<template>
  <div>
    <div class="flex flex-col gap-y-8">
      <div>
        <div class="text-center mb-6 md:mb-5">
          <p class="text-sm">Amount to transfer</p>
          <h2>{{ formatCurrency(selectedAmount || 0) }}</h2>
        </div>
        <div class="flex flex-col gap-y-4 md:gap-y-3" v-if="automatcherDetail">
          <div class="flex flex-row items-center gap-x-6">
            <p class="text-sm text-right w-1/2">Account name</p>
            <p class="text-primary text-left w-1/2">{{ automatcherDetail.automatcher.bankAccountName }}</p>
          </div>
          <div class="flex flex-row items-center gap-x-6">
            <p class="text-sm text-right w-1/2">BSB</p>
            <p class="text-primary text-left w-1/2">{{ automatcherDetail.automatcher.bsb }}</p>
          </div>
          <div class="flex flex-row items-center gap-x-6">
            <p class="text-sm text-right w-1/2">Account number</p>
            <p class="text-primary text-left w-1/2">{{ automatcherDetail.automatcher.bankAccountNumber }}</p>
          </div>
          <div class="flex flex-row items-center gap-x-6">
            <p class="text-sm text-right w-1/2">PayID</p>
            <p class="text-primary text-left w-1/2">{{ automatcherDetail.payId.payId }}</p>
          </div>
          <div class="justify-center text-center mt-6" v-if="referenceStyle && referenceStyle == 'center'">
            <div class="border-reference max-w-300 py-1">
              <p class="text-sm text-center">IMPORTANT! Use this reference</p>
              <h2>{{ prWithPartnerDetail.paymentRequestId.split("-").join("") }}</h2>
            </div>
          </div>
          <div class="flex flex-row items-center gap-x-6" v-else>
            <p class="text-sm text-right w-1/2">IMPORTANT! Use this reference</p>
            <p class="text-primary text-left w-1/2">
              {{ prWithPartnerDetail.paymentRequestId.split("-").join("") }}
            </p>
          </div>
          <div class="mt-8">
            <p class="text-body text-center">
              If the BSB/Account number isn't found on your internet banking portal, it's likely because you haven't made many payments to {{ appName }} previously - this is normal and won't impact your payment, but you can contact us to double check the details if you prefer.
            </p>
            <p class="text-body text-center mt-2">
              Your bank may show the account owner as “Monoova”, who are {{ appName }}’s banking service provider.
            </p>
          </div>
          <vs-divider class="my-8 max-w-xs mx-auto" />
        </div>
        <div v-if="description">
          <p class="text-body text-center mb-2">{{ description }}</p>
        </div>
        <div v-else>
          <p class="text-body text-center mb-2">You'll get a confirmation email and/or text message once your bank transfer is processed. This can take up to 3 business days.</p>
          <p class="text-body text-center mb-8">Ensure you use the transaction reference above when sending funds to {{ appName }}, otherwise your payment may be delayed further.</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-items-center text-center">
      <a class="btn-text" @click="closePayment">Cancel</a>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["description", "prWithPartnerDetail", "referenceStyle", "selectedAmount"],
    data() {
      return {
        appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "Flip",
      }
    },
    methods: {
      closePayment() {
        this.$emit("closePayment");
      }
    },
    computed: {
      automatcherDetail() {
        const partner = this.prWithPartnerDetail.partner;

        if (partner && partner.length && partner[0].monoova && partner[0].monoova.automatcher && partner[0].monoova.automatcher.bankAccountName && partner[0].monoova.automatcher.bsb && partner[0].monoova.automatcher.bankAccountNumber) {
          return partner[0].monoova;
        }

        return null;
      }
    }
  }
</script>

